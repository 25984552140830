import React from 'react'
import TextField from '@material-ui/core/TextField'
import CustomDialog from '../../organisms/Dialog/index'
import { useState } from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    return (
        <div className="customTextArea">
            {props.upperLabel ? (
                <>
                    <label>
                        {props.label}{' '}
                        {props?.lableInfoNoTooltip ? (
                            <IconButton>
                                <span
                                    className="material-icons"
                                    onClick={() => {
                                        if (props?.contents) {
                                            setToggleDialog(true)
                                        }
                                    }}
                                >
                                    info
                                </span>
                            </IconButton>
                        ) : null}
                    </label>
                    <TextField
                        multiline
                        rowsMax={props.rowsMax ? props.rowsMax : 4}
                        rows={props.rows ? props.rows : 1}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            shrink: shrink,
                        }}
                        id={props.id}
                        // label={props.label}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant="outlined"
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </>
            ) : (
                <TextField
                    multiline
                    rowsMax={props.rowsMax ? props.rowsMax : 4}
                    rows={props.rows ? props.rows : 1}
                    value={props.value}
                    autoFocus={props.autoFocus}
                    name={props.name}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    InputProps={{
                        inputProps: props.inputProps,
                        startAdornment: props.startAdornment,
                        shrink: shrink,
                    }}
                    id={props.id}
                    label={props.label}
                    type={props.type}
                    size={props.size}
                    disabled={props.disabled}
                    variant="outlined"
                    placeholder={props.placeholder}
                    error={props.error}
                    helperText={props.helperText}
                />
            )}

            <CustomDialog
                title={
                    <div className="bidConfirmTitle">
                        {props?.tooltiptitle}
                        <div className="ssmHead d-flex justify-content-center">
                            <button
                                type="button"
                                className="close"
                                id="cancel"
                                onClick={() => setToggleDialog(false)}
                            >
                                <span className="material-icons">clear</span>
                            </button>
                        </div>
                    </div>
                }
                open={toggleDialog}
                function={changeDialogStatus}
                className="bidConfirmationCstm"
            >
                <div className="">
                    {props?.contents
                        ? props?.contents
                              .replace(/\$enter/g, '\n')
                              .split('\n')
                              .map((line, index) => <p key={index}>{line}</p>)
                        : null}
                </div>
            </CustomDialog>
        </div>
    )
}

export default CustomInput
