import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Popper, Tooltip } from '@material-ui/core'
import { Menu } from '@material-ui/core'
import Loaders from '../../../custom/components/molecules/Loaders'

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function ImageCropper(props) {
    const [imgSrc, setImgSrc] = useState(props.imgSrc || '')
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const hiddenAnchorRef = useRef(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(props?.noAspect ? undefined : 16 / 9)
    const [disableZoomIn, setDisableZoomIn] = useState(false)
    const [disableZoomOut, setDisableZoomOut] = useState(false)
    const [aspectWidth, setAspectWidth] = useState(null)
    const [aspectHeight, setAspectHeight] = useState(null)
    const [customAspect, setCustomAspect] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        // const reader = new FileReader()
        // reader.addEventListener('load', () =>
        //     setImgSrc(reader.result ? reader.result.toString() : '')
        // )
        // reader.readAsDataURL(props.imgSrc[props.editId])
        if (props.imgSrc) {
            setCrop(undefined)
            setImgSrc(props.imgSrc)
        }
    }, [props.imgSrc])

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        } else {
            // Allow free cropping by setting crop to cover the entire image
            const { width, height } = e.currentTarget
            setCrop({
                unit: '%',
                x: 0,
                y: 0,
                width: 100,
                height: 100,
            })
        }
    }

    function onCropSave() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        props.getCroppedImage(previewCanvasRef.current.toDataURL())
        props.onClose()
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (props.noAspect) {
            return
        }

        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            const newCrop = centerAspectCrop(width, height, 16 / 9)
            setCrop(newCrop)
            setCompletedCrop(convertToPixelCrop(newCrop, width, height))
        }
    }

    function handleRotate(type) {
        setRotate((prevRotate) => (type === 'cw' ? prevRotate + 90 : prevRotate - 90) % 360)
    }

    function handleScale(type) {
        setScale((prevScale) => {
            const newScale = type === 'in' ? prevScale + 0.5 : prevScale - 0.5

            setDisableZoomIn(false)
            setDisableZoomOut(false)

            if (newScale <= 1) {
                setDisableZoomOut(true)
            } else if (newScale > 3) {
                setDisableZoomIn(true)
            }

            return newScale
        })
    }

    function handleCustomAspect() {
        if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(aspectWidth / aspectHeight)
            const newCrop = centerAspectCrop(width, height, aspectWidth / aspectHeight)
            setCrop(newCrop)
            setCompletedCrop(convertToPixelCrop(newCrop, width, height))
        }
        // const { width, height } = imgRef.current
        // const newCrop = centerAspectCrop(
        //     width,
        //     height,
        //     aspectWidth / aspectHeight
        // )
        // setCrop(newCrop)
        // setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleAspectPopper = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (aspect) {
            setCustomAspect(true)
        } else setCustomAspect(false)
    }, [aspect])

    return (
        <>
            {props.loading ? (
                <div className="noGrid cropLoader">
                    <Loaders isLoading={props.loading} />
                </div>
            ) : (
                <div className="imageCropCnt">
                    {/* <div className="Crop-Controls">
                <div>
                    <label htmlFor="scale-input">Scale: </label>
                    <input
                        id="scale-input"
                        type="number"
                        step="0.1"
                        value={scale}
                        disabled={!props.imgSrc}
                        onChange={(e) => setScale(Number(e.target.value))}
                    />
                </div>
                <div>
                    <label htmlFor="rotate-input">Rotate: </label>
                    <input
                        id="rotate-input"
                        type="number"
                        value={rotate}
                        disabled={!props.imgSrc}
                        onChange={(e) =>
                            setRotate(
                                Math.min(
                                    180,
                                    Math.max(-180, Number(e.target.value))
                                )
                            )
                        }
                    />
                </div>
            </div> */}

                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{
                                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                                }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    {!!completedCrop && (
                        <div>
                            <div className="cropPreview">
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            </div>
                            <div className="cropActions">
                                {!props.noAspect ? (
                                    <>
                                        <Tooltip
                                            title={`Turn ${aspect ? 'off' : 'on'} Aspect Ratio`}
                                        >
                                            <Button
                                                className="iconBtn"
                                                onClick={handleToggleAspectClick}
                                            >
                                                <span className="material-icons">aspect_ratio</span>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Set Custom Aspect Ratio">
                                            <Button
                                                className="iconBtn"
                                                disabled={customAspect ? false : true}
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleAspectPopper}
                                            >
                                                <span className="material-icons">crop_free</span>
                                            </Button>
                                        </Tooltip>
                                    </>
                                ) : null}
                                <Tooltip title="Rotate 90° counter-clock-wise">
                                    <Button className="iconBtn" onClick={() => handleRotate('ccw')}>
                                        <span className="material-icons">
                                            rotate_90_degrees_ccw
                                        </span>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Rotate 90° clock-wise">
                                    <Button className="iconBtn" onClick={() => handleRotate('cw')}>
                                        <span className="material-icons">rotate_90_degrees_cw</span>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Zoom in">
                                    <Button
                                        className="iconBtn"
                                        onClick={() => handleScale('in')}
                                        disabled={disableZoomIn}
                                    >
                                        <span className="material-icons">zoom_in</span>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Zoom out">
                                    <Button
                                        className="iconBtn"
                                        onClick={() => handleScale('out')}
                                        disabled={disableZoomOut}
                                    >
                                        <span className="material-icons">zoom_out</span>
                                    </Button>
                                </Tooltip>
                                <Button className="saveBtn" onClick={onCropSave}>
                                    {t('Save Selection')}
                                </Button>
                            </div>
                        </div>
                    )}
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        className="aspectEditCnt"
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <div className="aspectEdit">
                            <input
                                type="number"
                                placeholder="width"
                                value={aspectWidth}
                                onChange={(e) => setAspectWidth(Number(e.target.value))}
                            />
                            <input
                                type="number"
                                placeholder="height"
                                value={aspectHeight}
                                onChange={(e) => setAspectHeight(Number(e.target.value))}
                            />
                            <Button
                                disabled={
                                    aspectWidth == null || aspectHeight == null ? true : false
                                }
                                onClick={handleCustomAspect}
                            >
                                Save
                            </Button>
                        </div>
                    </Menu>
                </div>
            )}
        </>
    )
}

export default ImageCropper
